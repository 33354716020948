$black1: #000;
$black2: #19211E;

$dark1: #0F141E;
$dark2: #1a234e;
$dark3: #141414;
$dark4: #444;

$point1: #005b38;
$point2: #ffcc3d;
$point3: #1b9365;
$point4: #fee5a9;
$point5: #a6c6ad;
$point6: #fff9e8;

$light-purple1: #f2f9ff;
$light-purple2: #EAF5FF;

$deep-purple1: #E1EAFB;
$deep-purple2: #E5E8FC;
$deep-purple3: #9162F5;

$gray1: #111;
$gray2: #333;
$gray3: #666;
$gray4: #999;
$gray5: #c0c0c0;
$gray6: #e1e1e1;
$gray7: #ccc;
$gray8: #d9d9d9;
$gray9: #73798f;

$light-gray1: #f2f3f5;
$light-gray2: #f0f0f0;

$green1: #136849;
$green2: #10A46E;
$green3: #35CBB0;

$white: #fff;
$white1: #f5f9ff;

$blue1: #4160E1;
$blue2: #3D55DF;
$blue3: #30409E;
$blue4: #4884F6;
$blue5: #5AAAF0;