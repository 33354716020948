@import "../../common/variables";             // 변수
@import "../../common/mixin";                 // 믹스인


.hs-term-header {
    background: url("../../../assets/images/terms_header.png") #06926a left top no-repeat;
    @include position (fixed, 0, null, null, 0);
    z-index: 9;
    width: 100%;
    min-height: 50px;

    &-title {
        padding: 10px 20px;
        color: #fff;
        font-size: 18px;
        font-weight: 400;
    } // .hs-term-header-title
}