@import "../../styles/common/mixin";

.hs-visual-section {
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../../assets/images/company_sub_background.png') no-repeat center center / cover;
    box-sizing: border-box;
    padding: 15px;
    font-size: 30px;
    word-break: keep-all;
    color: #fff;
    text-align: center;

    @media (min-width: 992px) {
        height: 500px;
        padding: 0;
        font-size: 55px;
    }
}

.hs-intro {
    &-text {
        margin: 0 auto;
        width: 100%;
        word-break: keep-all;
        max-width: 850px;
        font-size: 20px;

        strong {
            display: block;
        }

        &:not(:last-of-type) {
            margin-bottom: 20px;
        }

        &:last-of-type {
            margin-bottom: 50px;
        }

        @media (min-width: 992px) {
            font-size: 30px;

            &:not(:last-of-type) {
                margin-bottom: 40px;
            }

            &:last-of-type {
                margin-bottom: 150px;
            }
        }
    } // .hs-intro-text

    &-contents {
        &-list {
            @media (min-width: 992px) {
                display: flex;
                justify-content: center;
                gap: 20px;
            }
        } // .hs-intro-contents-list

        &-item {
            border-radius: 10px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.23);
            padding: 50px 30px;
            box-sizing: border-box;
            min-height: 300px;
            position: relative;
            background-color: #fff;
            z-index: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: left;

            &:not(:last-child) {
                margin-bottom: 20px;
            }

            $imaggIcons: (
                    innovation: #0fd07f,
                    growth: #4489fe,
                    collaboration: #ffaf50
            );

            @each $imageIcon, $pointColor in $imaggIcons {
                &.#{$imageIcon} {
                    &::before {
                        display: block;
                        width: 90px;
                        height: 90px;
                        @include position (absolute, 30px, 25px);
                        background: url("../../assets/images/company-intro-#{$imageIcon}.jpg") no-repeat center center / 90px 90px;
                        z-index: -1;
                        content: '';
                    }

                    .hs-intro-contents-title-point {
                        color: $pointColor;
                    }
                }
            }

            @media (min-width: 992px) {
                transition: transform .3s;
                flex: 1;

                &:not(:last-child) {
                    margin-bottom: 0;
                }

                &:hover {
                    transform: translateY(-15px);
                }
            }
        } // .hs-intro-contents-item

        &-title {
            margin-bottom: 40px;

            &-main {
                display: block;
                font-weight: bold;
                font-size: 30px;
                margin-bottom: 5px;
            } // .hs-intro-contents-title-main

            &-point {
                display: block;
                font-size: 22px;
                font-weight: 500;
            } // .hs-intro-contents-title-point
        }

        &-text {
            word-break: keep-all;
            font-size: 22px;
            letter-spacing: -.3px;
        } // .hs-intro-contents-text
    }
}

.hs-history {
    &-list {
        width: 100%;
    } // .hs-history-list

    &-item {
        text-align: left;
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        width: 100%;
        position: relative;

        @media (min-width: 992px) {
            &::before {
                display: block;
                width: 50%;
                box-sizing: border-box;
                content: '';
            }
        }

        &:not(:last-child)::after {
            @include position (absolute, 0, null, null, 8px);
            background-color: #000;
            width: 1px;
            height: 100%;
            display: block;
            content: '';

            @media (min-width: 992px) {
                left: 50%;
            }
        }

        &:first-child::after {
            top: 25px;
        }

        &:nth-last-child(2)::after {
            height: calc(100% + 20px);
        }

        @media (min-width: 992px) {
            &:nth-child(even) {
                order: 1;
                text-align: right;

                &::before {
                    order: 2;
                    border-right: none;
                }
            }
        }
    } // .hs-history-item

    &-title {
        @include position (absolute, 0, null, null, 0);
        padding-left: 30px;
        font-size: 26px;
        font-weight: bold;

        @media (min-width: 992px) {
            width: 50%;
            left: -30px;
            padding-left: 0;
        }

        &::before {
            @include position (absolute, 11px, null, null, 0);
            display: block;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background-color: #000;
            content: '';

            @media (min-width: 992px) {
                left: -38px;
            }
        }

        @media (min-width: 992px) {
            @at-root .hs-history-item:first-child & {
                font-size: 42px;

                &::before {
                    top: 24px;
                }
            }

            @at-root .hs-history-item:nth-child(odd) & {
                left: calc(50% + 30px);
            }

            @at-root .hs-history-item:nth-child(even) & {
                &::before {
                    left: auto;
                    right: -40px;
                }
            }
        }
    } // .hs-history-title

    &-contents {
        padding: 45px 30px 20px;
        box-sizing: border-box;
        font-size: 16px;
        letter-spacing: -.2px;

        @media (min-width: 992px) {
            @at-root .hs-history-item:first-child & {
                padding: 67px 30px 20px;
            }
        }

        @media (min-width: 992px) {
            width: 50%;
        }

        &-item {
            display: flex;
            flex-wrap: wrap;

            &:not(:last-child) {
                margin-bottom: 5px;
            }

            @media (min-width: 992px) {
                @at-root .hs-history-item:nth-child(even) & {
                    justify-content: flex-end;
                }
            }
        } // .hs-history-contents-item

        &-title {
            font-weight: bold;
            margin-right: 10px;
        } // .hs-history-contents-title
    } // .hs-history-contents
}