@import "../../styles/common/reset";
@import "../../styles/common/variables";
@import "../../styles/common/mixin";

.hs-visual {
    font-family: Pretendard;
    &-section {
        display: flex;
        width: 100%;
        margin: 0 auto;
        min-height: 500px;
        // background: #fff url('../../assets/images/index_main_m.png') no-repeat right bottom / 280px 258px;
        background: #fff url('../../assets/images/main_bg.png') no-repeat right bottom / 280px 258px;
        background-size: cover;
        justify-content: center;

        @media (min-width: 992px) {
            align-items: center;
            min-height: 633px;
            background: #fff url('../../assets/images/main_bg.png') no-repeat center;
            background-size: cover;
            // background: #fff url('../../assets/images/main_bg.png') no-repeat right bottom / 745px 478px;
            // background-position: right 128px bottom 134px;
            // background: #fff url('../../assets/images/index_main.png') no-repeat center center / cover;
        }

        @media (max-width: 430px) {
            box-sizing: border-box;
        }

        @media (max-height: 750px) {
            height: 660px;
        }

        &-inner {
            // max-width: 1200px;
            width: 100%;
            margin: 0 auto;
            padding: 20px;
            text-align: center;
            box-sizing: border-box;
            position: relative;
            &-logo {
                display: flex;
                align-items: center;
                width: 100%;
                // height: 653px;
                height: 880px;
                flex-shrink: 0;
                background-image: url('../../assets/images/main_bg_logo.png');
                background-size: cover;
                @media (max-height: 750px) {
                    height: 660px;
                }

                @media (max-width: 430px) {
                    height: 600px;
                }
            }

            @media (max-width: 568px) {
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 20%;
                justify-content: flex-start;
                word-break: break-all;
            }

            @media (min-width: 568px) and (max-width: 992px) {
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            @media (max-width: 430px) {
                margin-top: 0;
            }


            @media (min-width: 992px) and (max-width: 1200px) {
                padding: 0 50px;
                box-sizing: border-box;
            }
        } // .hs-visual-section-inner

        &-title {
            font-size: 30px;
            margin-bottom: 26px;
            color: $dark2;
            font-weight: 700;
            letter-spacing: -0.21px;

            @media (min-width: 993px) {
                font-size: 66px;
                line-height: 78px;
            }

            // @media (max-height: 750px) {
            //     font-size: 62px;
            // }

            .point {
                display: block;
                // color: $point2;
                // font-weight: bold;
            }
        }

        &-list {
            @media (max-width: 992px) {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        &-item {
            color: $dark2;
            position: relative;
            // padding-left: 25px;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.21px;

            // &::before {
            //     @include position (absolute, 12px, null, null, 0);
            //     width: 10px;
            //     height: 10px;
            //     display: block;
            //     background-color: #fff;
            //     border-radius: 50%;
            //     content: '';
            // }

            // &:not(:last-child) {
            //     margin-bottom: 10px;
            // }
        }
        button.primary {
            width: 197px;
            height: 54px;
            flex-shrink: 0;
            border-radius: 8px;
            margin-top: 44px;
            border: 1px solid $green2;
            transition: background-color 0.3s;
            background-color: $green2;
            &.dark {
                background-color: $dark2;
                border-color: $dark2;
                &:hover {
                    background-color: transparent;
                    a {
                        color: $dark2;
                    }
                }
            }
            &:hover {
                background-color: transparent;
                a {
                    color: $green2;
                }
            }
            a {
                font-family: Pretendard;
                color: $white;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                letter-spacing: -0.18px;
            }
        }
    } // .hs-visual-section
}

.hs-intro-info {
    &-list, &-business-list {
        display: flex;
    }

    &-item {
        flex: 1;
        text-align: center;
        width: 340px;
        min-width: 340px;
        height: 360px;
        box-shadow: 0 0 12px rgba(65, 112, 183, 0.25);
        margin-right: 30px;
        padding: 40px;
        box-sizing: border-box;
        background-color: $white;

        &::before {
            display: block;
            width: 158px;
            height: 134px;
            margin: 0 auto 10px;
            content: '';
        }

        $images: (collection, analysis, application);

        @each $image in $images {
            &.#{$image}::before {
                background: url("../../assets/images/index_section_info_#{$image}.png") no-repeat center center / contain;
            }
        }
    }

    &-business-item {
        flex: 1;
        text-align: center;
        width: 350px;
        min-width: 350px;
        height: 380px;
        margin-right: 20px;
        padding: 38px;
        box-sizing: border-box;
        border-radius: 20px;
        background-color: $white;

        $images: (tas, tma, tms);

        @each $image in $images {
            .#{$image}::before {
                display: block;
                width: 101px;
                height: 63px;
                margin: 0 auto 10px;
                background-image: url("../../assets/images/ico_#{$image}.png");
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
                content: '';
            }
        }
        .tas {
            margin-top: 29px;
            margin-bottom: 30px;
        }
        .tma {
            margin-top: 27px;
            margin-bottom: 31px;
        }
        .tms {
            margin-top: 27px;
            margin-bottom: 28px;
        }
    }

    &-title {
        font-size: 26px;
        font-weight: bold;
        margin-bottom: 4px;
        color: #141414;
        text-align: center;
        &.business {
            font-size: 22px;
            font-weight: 400;
            line-height: 40px; /* 181.818% */
        }
        &-emphasis {
            color: #222;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px; /* 95.238% */
        }
        .white {
            color: $white;
        }
    }

    &-sub {
        &-list {
            width: 250px;
            margin: 0 auto;
        }
        &-item {
            width: 245px;
            font-size: 17px;
            line-height: 24px;
            padding-left: 10px;
            text-align: left;

            &.business::before {
                display: inline-block;
                width: 0;
                height: 0;
                margin-right: 0;
                content: '';
            }
            &::before {
                display: inline-block;
                width: 6px;
                height: 6px;
                margin-right: 10px;
                vertical-align: middle;
                border-radius: 50%;
                background-color: #000;
                content: '';
            }
        }
    }
}

.hs-section-title {
    font-size: 46px;
    word-break: keep-all;
    font-weight: 500;
    position: relative;
    text-align: center;
    line-height: 40px;
    color: #141414;
    margin-bottom: 30px;
    &.dark2 {
        color: $dark2;
    }
    &.white {
        color: #fff;
    }

    @media (min-width: 992px) {
        font-size: 50px;
    }

    &-underline {
        display: inline-block;
        margin-right: 5px;
        position: relative;
        z-index: 1;
        font-weight: bold;
        color: $point1;

        &::before {
            display: inline-block;
            width: 100%;
            padding: 0 5px;
            height: 20px;
            position: absolute;
            left: 50%;
            bottom: 10px;
            transform: translateX(-50%);
            background-color: $point4;
            z-index: -1;
            content: '';

            @media (min-width: 992px) {
                height: 25px;
            }
        }
    }
}

.hs-section-sub-title {
    font-size: 28px;
    color: $dark4;
    margin-bottom: 110px;
    font-weight: 300;
    letter-spacing: -1px;
    &.light-margin {
        margin-bottom: 40px;
    }
    &.dark2 {
        color: $dark2;
    }
    &.dark4 {
        color: $dark4;
    }
    &.white {
        color: #fff;
    }
}

.hs-section-ai {
    &-list {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }

    &-item {
        padding: 30px;
        border-radius: 10px;
        box-sizing: border-box;
        vertical-align: top;
        width: 100%;
        border: 1px solid #ccc;
        transition: box-shadow .3s;
        position: relative;
        background-color: #fff;
        z-index: 1;
        text-align: center;
        word-break: keep-all;

        @media (min-width: 992px) {
            width: calc(50% - 20px);
            text-align: left;

            &:hover {
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.23);
            }
        }

        &::before {
            width: 150px;
            height: 150px;
            display: block;
            margin: 0 auto;
            content: '';

            @media (min-width: 992px) {
                z-index: -1;
                position: absolute;
                top: 30px;
                right: 30px;
            }
        }

        $images: (recommend, communication, ad, report);

        @each $image in $images {
            &.#{$image}::before {
                background: url("../../assets/images/index_ai_#{$image}.jpg") no-repeat center center / contain;
            }
        }
    }

    &-title {
        display: block;
        margin-bottom: 30px;
        font-size: 32px;

        @media (min-width: 992px) {
            margin-bottom: 75px;
        }

        strong {
            display: block;
        }
    }

    &-text {
        font-size: 22px;
        word-break: keep-all;
    }

    &-consulting {
        &-list {
            @media (min-width: 992px) {
                display: flex;
                gap: 20px;
            }
        }

        &-item {
            flex: 1;
            padding: 160px 30px 30px;
            border-radius: 10px;
            box-sizing: border-box;
            vertical-align: top;
            width: 100%;
            border: 1px solid #ccc;
            transition: box-shadow .3s;
            position: relative;
            background-color: #fff;
            z-index: 1;
            text-align: center;
            font-size: 28px;

            @media (max-width: 992px) {
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
            }

            @media (min-width: 992px) {
                &:hover {
                    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.23);
                }

                strong {
                    display: block;
                }
            }

            &::before {
                z-index: -1;
                position: absolute;
                top: 30px;
                left: 50%;
                transform: translateX(-50%);
                width: 80%;
                height: 120px;
                box-sizing: border-box;
                display: block;
                text-align: center;
                content: '';
            }

            $images: (kubeflow, dataset, algorithm, performance, saas);

            @each $image in $images {
                &.#{$image}::before {
                    background: url("../../assets/images/index_ai_#{$image}.jpg") no-repeat center center / contain;
                }
            }
        }

    }
}

.hs-section-service {
    $services: (
        targeting: #a78ee5,
        tason: #4ace95,
        tms: #3bb277,
        hcloud: #4fb2be
    );
    &-item {
        // width: 1208px;
        // height: 124px;
        border-radius: 20px;
        display: flex;
        flex-direction: row;
        grid-column: span 1 / span 1;
        padding: 2rem 1.75rem;
        border: 2px solid $light-gray2;
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
        .icon-container {
            width: 62px;
            padding-right: 1.25rem;
        }
        .icon {
            display: inline-block;
            background-repeat: no-repeat;
            width: 62px;
            height: 62px;
        } 
        .service-item-container {
            display: flex;
            flex-direction: column;
            padding-left: 39px;
            border-left: 1px solid $gray8;
            align-items: self-start;
            .service-label {
                background-color: $light-gray1;
                padding: 4px 12px;
                border-radius: 16px;
                color: $gray9;
                font-size: 14px;
                margin-bottom: 16px;
            }
            .service-name {
                font-size: 30px;
                font-weight: 500;
                margin-bottom: 24px;
            }
            .service-description {
                color: $dark2;
                font-size: 18px;
                line-height: 26px;
                text-align: left;
                margin-bottom: 20px;
            }
            .goto-service {
                color: $dark2;
                font-weight: 600;
                font-size: 18px;
                &.emphasis {
                    color: $green2;
                }
                a {
                    margin-right: 6px;
                }
            }
        }
    }
    &-item.targeting {
        // border-radius: 8px;
        // background: linear-gradient(90deg, #C69BFC 0%, #285588 100%);
        .icon {
            background-image: url('../../assets/images/icon/ico_ma.svg');
        } 
        &:hover {
            // box-shadow: 10px 10px 30px 0px #30409E;
        }
    }
    &-item.tason {
        // border-radius: 8px;
        // background: linear-gradient(90deg, #4AD983 0%, #4f74f6 100%);
        .icon {
            background-image: url('../../assets/images/icon/ico_tason.svg');
        } 
        &:hover {
            // box-shadow: 10px 10px 30px 0px #30409E;
        }
    }
    &-item.tms {
        // border-radius: 8px;
        // background: linear-gradient(90deg, #4AD983 0%, #00417D 100%);
        .icon {
            background-image: url('../../assets/images/icon/ico_tms.svg');
        } 
        &:hover {
            // box-shadow: 10px 10px 30px 0px #30409E;
        }
    }
    &-item.hcloud {
        // border-radius: 8px;
        // background: linear-gradient(90deg, #43BBC2 0%, #E04184 100%);
        .icon {
            background-image: url('../../assets/images/icon/ico_cloud.svg');
        } 
        &:hover {
            // box-shadow: 10px 10px 30px 0px #30409E;
        }
    }

    // @each $service, $color in $services {
    //     &-item.#{$service} {
    //         .hs-section-service {
    //             &-label {
    //                 background-color: $color;
    //             }

    //             &-tag-item {
    //                 color: $color;
    //             }
    //         }
    //     }
    // }
    &-list {
        margin-top: -30px;
    }

    &-link {
        position: relative;
        border: 1px solid #aaa;
        border-radius: 10px;
        display: block;
        padding: 50px 30px 30px;
        text-align: left;

        @media (min-width: 992px) {
            display: flex;
            flex-wrap: wrap;
            transition: box-shadow .3s;
            padding: 60px 50px 50px;

            &:hover {
                box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.23);
            }
        }
    }

    &-label {
        position: absolute;
        top: 0;
        left: 30px;
        background-color: #000;
        color: #fff;
        font-size: 16px;
        min-width: 100px;
        font-weight: bold;
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
        padding: 5px 20px;
        box-sizing: border-box;
        text-align: center;

        @media (min-width: 992px) {
            left: 50px;
            font-size: 20px;
            min-width: 180px;
        }
    }

    &-title {
        text-align: left;
        font-size: 30px;
        line-height: 40px;
        word-break: keep-all;
        color: #000;
        box-sizing: border-box;
        margin-bottom: 10px;
        display: block;
        width: 80%;

        @media (min-width: 992px) {
            width: 38%;
            padding-right: 150px;
            margin-bottom: 0;
        }
    }

    &-contents {
        text-align: left;

        @media (min-width: 992px) {
            flex: 1;
        }
    }

    &-tag {
        &-list {
            margin-bottom: 25px;
        }

        &-item {
            display: inline-block;
            color: #000;
            font-size: 24px;
            font-weight: bold;

            &:not(:last-child) {
                margin-right: 40px;
            }
        }
    }

    &-desc {
        font-size: 20px;
        color: #000;
        word-break: keep-all;

        &:not(:last-of-type) {
            margin-bottom: 10px;
        }

        @media (min-width: 992px) {
            &:last-of-type {
                margin-bottom: 50px;
            }
        }
    }

    &-image {
        display: none;

        @media (min-width: 992px) {
            display: block;
            width: 100%;
            margin-bottom: 50px;
        }
    }

    &-button {
        &::after {
            position: absolute;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
            display: inline-block;
            border-width: 2px 2px 0 0;
            border-style: solid;
            content: '';
        }

        @media (max-width: 992px) {
            position: absolute;
            top: 55px;
            right: 30px;
            border-radius: 50%;
            background-color: #000;
            color: transparent;
            width: 35px;
            height: 35px;

            &::after {
                left: 10px;
                width: 10px;
                height: 10px;
                border-color: #fff;
            }
        }

        @media (min-width: 992px) {
            font-size: 20px;
            color: $dark1;
            border: 1px solid #000;
            border-radius: 5px;
            padding: 10px 50px 10px 20px;
            display: inline-block;
            font-weight: bold;
            position: relative;

            &::after {
                top: 50%;
                right: 20px;
                width: 10px;
                height: 10px;
                border-color: #000;
            }
        }

        &-wrap {
            text-align: right;
            width: 100%;
        }
    }
}
.service-item-container {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    .service-label {
        background-color: $gray6;
        padding: 4px 12px;
        border-radius: 16px;
        color: $dark3;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 16px;
    }
}

.goto-service {
    color: $dark2;
    font-weight: 600;
    font-size: 18px;
    margin-top: 20px;
    &:hover {
        text-decoration: underline;
    }
    &.emphasis {
        color: $green2;
    }
    a {
        margin-right: 6px;
    }
}

.hs-section-link {
    display: block;
    border-radius: 20px;
    transition: box-shadow 0.2s;

    &:hover {
        box-shadow: 0 0 25px 0 rgb(0 0 0 / 23%);
    }
    
    @media (max-width: 992px) {
        width: 100%;    
    }
}

.hs-section-inner {
    &-title {
        color: $dark2;
        font-size: 46px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        margin-bottom: 45px;
        @media (max-width: 430px) {
            font-size: 30px;
        }
    }
    &-sub-title {
        color: $dark4;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        height: 54px;
        letter-spacing: -0.22px;
        margin-bottom: 45px;
        @media (max-width: 430px) {
            height: auto;
        }
    }
    &-swiper-title {
        color: $green2;
        text-align: left;
        font-family: Inter;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px; /* 86.667% */
        margin-bottom: 38px;
    }
}

.hs-contact-button {
    width: 180px;

    &-link {
        display: inline-block;
    } // .hs-contact-button-link

    &-wrap {
        margin-top: 25px;
    } // .hs-contact-button-wrap
}

.hs-consulting {
    &-container {
        display: flex;
        justify-content: space-evenly;
        @media (max-width: 430px) {
            flex-direction: column;
        }
    }
    &-item {
        .img-content {
            display: inline-block;
            width: 354px;
            height: 257px;
            background-repeat: no-repeat;
            background-size: contain;
            margin-bottom: 71px;
            &.solution {
                background-image: url('../../assets/images/solution.png');
            }
            &.apply {
                background-image: url('../../assets/images/apply.png');
            }
            &.manage {
                background-image: url('../../assets/images/manage.png');
            }
        }
        .title {
            color: $dark3;
            font-size: 24px;
            font-weight: 500;
            letter-spacing: -1px;
            margin-bottom: 31px;
        }
        .description {
            color: $dark3;
            font-size: 18px;
            letter-spacing: -1px;
        }
    }
}

.insight-image {
    width: 320px;
    height: 172px;
    background: $gray8;
    margin-bottom: 31px;
    cursor: pointer;

    $images: (0, 1, 2, 3, 4, 5, 6, 7, 8);

    @each $image in $images {
        &.insight-#{$image} {
            background-image: url("../../assets/images/insight_#{$image}.png");
            background-size: 320px 172px;
            background-repeat: no-repeat;
        }
    }
    
}
.notice-item, .insight-item {
    width: 320px;
    height: 125px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 24px 21px;
    box-sizing: border-box;
    text-align: left;
    .bbs-type {
        color: #666;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.15px;
        text-transform: uppercase;
        margin-bottom: 12px;
    }
    .title {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        min-height: 50px;
        letter-spacing: -0.18px;
        a {
            color: #000;
            &:hover {
                color: #666;
            }
        }
    }
}