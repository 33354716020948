@import "../../common/variables";             // 변수
@import "../../common/mixin";                 // 믹스인

.hs-footer {
    background-color: $dark1;
    &-subscribe-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 56px 0;
        @media (max-width: 430px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
        }
        p {
            color: #FFF;
            font-family: Pretendard;
            font-size: 20px;
            font-style: normal;
            font-weight: 300;
            line-height: 24px; /* 75% */
            width: 430px;
        }
        div {
            position: relative;
            input {
                box-sizing: border-box;
                color: #999;
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.18px;
                border-radius: 8px;
                width: 610px;
                height: 70px;
                text-indent: 20px;
                padding-right: 180px;
                &[type='text'] {
                    text-align: center;
                    padding-right: 0;
                }
                @media (max-width: 430px) {
                    width: 390px;
                }
            }
            button {
                position: absolute;
                width: 156px;
                height: 46px;
                flex-shrink: 0;
                background-color: $green2;
                transition: background-color 0.3s;
                color: $white;
                border-radius: 8px;
                right: 12px;
                top: 12px;
                font-size: 16px;
                &:hover {
                    background-color: $green1;
                }
                @media (max-width: 430px) {
                    width: 120px;
                    right: 20px;
                }
            }
        }
    }
    &-inner {
        max-width: 1086px;
        width: 100%;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 50px 15px;

        @media (max-width: 430px) {
            max-width: 430px;
        }

        @media (min-width: 992px) {
            padding: 0;
        }

        @media (min-width: 1200px) {
            padding: 0;
        }
    } // .hs-footer-inner

    &-company-logo {
        vertical-align: top;
        width: 131px;
        height: 32px;

        @media (min-width: 992px) {
            width: 131px;
            height: 32px;
        }

        &-title {
            display: block;
            margin: 20px 0 10px;
        } // .hs-footer-company-title
        &-wrap {
            margin-bottom: 23px;
        } // .hs-footer-company-logo-wrap
    } // .hs-footer-company-logo

    &-company-info {
        &-area { 
            padding: 56px 0 56px 0;
            position: relative;
        }// .hs-footer-company-info-area

        &-list {
            margin: 0 0 10px 0;
            color: $gray7;

            @media (min-width: 992px) {
                // display: grid;
                // grid-template-columns: repeat(2, 1fr);
                display: flex;
                justify-content: space-between;
                & > dl {
                    width: 60%;
                    span {
                        cursor: pointer;
                    }
                }
            }
        } // .hs-footer-company-info-list

        &-item {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #444;

            @media (min-width: 992px) {
                &.right {
                    justify-content: right;
                }
            }

            &.order {
                order: 1;
            }
        }// .hs-footer-company-info-item


        &-title {
            margin-right: 5px;
        }// .hs-footer-company-info-item-title
    }

    &-terms {
        &-list {
            display: flex;
            margin-bottom: 10px;

            @media (min-width: 992px) {
                // @include position (absolute, 68px, 0);
                margin-bottom: 0;
            }
        } // .hs-footer-term-list

        &-item {
            &:not(:first-child) {
                margin-left: 20px;
            }
        } // .hs-footer-term-item

        &-link {
            font-size: 14px;
            color: $gray7;

            &:hover {
                border-bottom: 1px solid $gray7;
            }
        } // .hs-footer-link
    }

    &-sns {
        &-list {
            display: flex;
            gap: 10px;
            margin-bottom: 10px;

            @media (min-width: 992px) {
                gap: 20px;
                @include position(absolute, 72px, 0);
                margin-bottom: 0;
            }
        } // .hs-footer=sns-list
        
        &-link {
            display: block;
            width: 24px;
            height: 24px;
            transition: all .2s;

            @media (min-width: 992px) {
                width: 24px;
                height: 24px;
            }

            $snsIcons: (facebook, blog, wordpress);
            @each $snsIcon in $snsIcons {
                &.#{$snsIcon} {
                    background: url("../../../assets/images/footer_icon_#{$snsIcon}.svg") no-repeat center center / contain;
            
                    // &:hover {
                    //     background: url("../../../assets/images/footer_icon_#{$snsIcon}_color.svg") no-repeat center center / contain;
                    // }
                }
            }
        } // .hs-footer-sns-link
    }

    &-copyright {
        font-size: 14px;
        color: $gray7;

        @media (min-width: 992px) {
            text-align: right;
        }
    } // .hs-footer-acopyright
}
.hs-company-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

hr.hs-footer-divider {
    height: 1px;
    background-color: $gray7;
    margin: 0;
    border: none;
}