@import "../common/variables";             // 변수
@import "../common/mixin";                 // 믹스인

.hs-terms {
    &-main {
        margin-top: 50px;

        &-inner {
            padding: 30px;
            box-sizing: border-box;
        } // .hs-terms-main-inner
    } // .hs-terms-main

    &-section {
        font-size: 12px;

        &:not(:first-child) {
            margin-top: 30px;
        }

        &-title {
            font-size: 13px;
            font-weight: 500;
            margin-bottom: 10px;
        } // .hs-terms-section-title

        &-text {
            &:not(:last-child) {
                margin-bottom: 10px;
            }

            &.warning {
                color: red;
            }
        } // .hs-terms-section-text

        &-list {
            &-item {
                padding-left: 15px;
                text-indent: -15px;
                
                &:not(:last-child) {
                    margin-bottom: 5px;
                }
            } // .hs-terms-section-item
        } // .hs-terms-section-link

        &-number {
            &-list {
               margin: 5px 0 0 15px;
            } // .hs-terms-section-number-liist

            &-item {
                &:not(:last-child) {
                    margin-bottom: 5px;
                }
            } // .hs-terms-section-number-item
        }
    }

    &-user-info {
        &-title {
            font-weight: 500;
        } // .hs-terms-user-info-title

        &-list {
            margin-top: 5px;
        } // .hs-terms-user-info-list

        &-item {
            display: flex;
            text-indent: 0;
            
            dt {
                margin-right: 5px;
            }

            dd {
                flex: 1;
            }
        }  // .hs-terms-user-info-item

        &-management-title {
            display: block;
            font-weight: 500;
            margin-bottom: 5px;
        } // .hs-terms-user-info-manement-tile
    }

    &-definition {
        &-list {
            margin-left: 15px;
        } // .hs-terms-definition-list

        &-item {
            text-indent: 0;

            &:not(:first-child) {
                margin-top: 5px;
            }

            dd {
                margin-left: 10px;
            }
        }// .hs-terms-definition-item

    }

    &-dash {
        &-item {
            position: relative;
            padding-left: 15px;

            &::before {
                position: absolute;
                top: 7px;
                left: 0;
                display: block;
                width: 10px;
                height: 1px;
                background-color: #000;
                content: '';
            }
        } // .hs-terms-dash-item
    }

    &-table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;

        &-wrap {
            width: 100%;
            margin-top: 20px;
        } // .hs-terms-table-wrap

        &-caption {
            text-align: left;
            font-weight: 500;
            padding-bottom: 10px;
            border-bottom: 1px solid #000;
        } // .hs-terms-table-caption

        thead tr {
            background: #eef1f7;
            color: #222;
            font-weight: normal;
        }

        tbody tr {
            text-align: center;

            &.left td {
                text-align: left;
            }

            &:not(:last-child) {
                border-bottom: 1px solid #ddd;
            }

            &:last-child {
                border-bottom: 1px solid #000;
            }
        }

        th, td {
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;
            padding: 10px;
            box-sizing: border-box;

            &:first-child {
                border-left: none;
            }

            &:last-child {
                border-right: none;
            }
        }
    }

    &-link {
        margin-top: 5px;
        display: inline-block;
        color: #000;
        border-bottom: 1px solid #000;

        &:hover {
            color: $point1;
            border-color: $point1;
        }
    } // .hs-terms-table-link
} //  // .hs-terms-table