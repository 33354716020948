@import "../common/variables";             // 변수
@import "../common/mixin";                 // 믹스인

.hs-main {
  &-title {
    display: block;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 20px;
    color: $point1;
    word-break: keep-all;

    @media (min-width: 992px) {
      font-size: 40px;
    }
  }

  &-desc {
    font-size: 20px;
    color: #222;
    letter-spacing: -0.3px;
    font-weight: 500;
    margin: 0 auto 40px;
    max-width: 790px;
    width: 100%;
    word-break: keep-all;

    @media (min-width: 992px) {
      font-size: 22px;
    }
  }

  &-list {
    @media (min-width: 576px) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  &-item {
    @media (min-width: 576px) {
      width: 50%;
      margin: 0 auto;
    }

    @media (min-width: 992px) {
      max-width: initial;
      margin: auto;
      flex: 1;
      transition: transform .3s;

      &:hover {
        transform: translateY(-15px);
      }
    }
  }
}

.hs-sub {
  &-title {
    display: block;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 20px;
    color: $point1;
    word-break: keep-all;

    @media (min-width: 992px) {
      font-size: 40px;
      text-align: left;
    }

    strong {
      text-align: center;

      @media (min-width: 992px) {
        text-align: left;
      }
    }
  }
}

.hs-button {
  width: 100%;
  height: 50px;
  border-radius: 3px;
  font-size: 20px;
  letter-spacing: -0.92px;
  border: 2px solid $point1;
  color: $point1;
  font-weight: bold;
  background-color: #fff;
  transition: background-color .2s;

  @media (min-width: 992px) {
    width: auto;
    min-width: 300px;
    height: 68px;
    font-size: 24px;
  }

  &:hover {
    background-color: $point1;
    color: #fff;
  }

  &:not(:last-child) {
    margin-bottom: 10px;

    @media (min-width: 992px) {
      margin: 0 20px 0 0;
    }
  }

  &.primary {
    background-color: $point1;
    color: #fff;

    &:hover {
      background-color: #004025;
    }
  }

  &-container {
    @media (min-width: 992px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.hs-tms-intro {
  &-list {
    margin-top: 50px;
  }

  &-item {
    @media (min-width: 992px) {
      display: flex;
      align-items: center;

      &:nth-child(odd) {
        flex-direction: row-reverse;
      }
    }

    &:not(:last-child) {
      margin-bottom: 100px;
    }

    &-image {
      $introImages: (cost, cross, data);
      @each $introImage in $introImages {
        @at-root .#{$introImage} & {
          width: 90%;
          height: 150px;
          margin: 0 auto 40px;
          background: url("../../assets/images/tms_intro_#{$introImage}.png") no-repeat center center / contain;

          @media (min-width: 992px) {
            width: 50%;
            min-height: 300px;
            margin: 0;
          }
        }
      }
    }

    &-inner {
      text-align: left;

      @media (min-width: 992px) {
        width: 50%;
      }
    }
  }

  &-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
    display: block;
    text-align: center;
    word-break: keep-all;

    @media (min-width: 992px) {
      text-align: left;
      margin-bottom: 35px;
    }
  }

  &-desc {
    color: #222;
    letter-spacing: -0.3px;
    font-size: 20px;
    word-break: keep-all;
    text-align: center;

    @media (min-width: 992px) {
      text-align: left;
    }
  }
}

.hs-tms-competitiveness {
  &-list {
    margin-top: 50px;
  }

  &-item {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    padding: 30px 20px;

    @media (min-width: 576px) {
      min-height: 300px;
      padding: 30px 50px;
    }

    @media (min-width: 992px) {
      min-height: 380px;
      display: flex;
      align-items: center;

      &:nth-child(even) {
        flex-direction: row-reverse;

        .hs-tms-competitiveness-image {
          background-position: center right;
        }

        .hs-tms-competitiveness-inner {
          padding-right: 20px;
          box-sizing: border-box;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  &-image {
    display: none;

    @media (min-width: 576px) {
      width: 100%;
      display: block;
      margin-bottom: 50px;
    }

    @media (min-width: 992px) {
      width: 60%;
      margin-bottom: 0;
    }

    $competitivenessImages: (performance, technology, marketing);
    @each $competitivenessImage in $competitivenessImages {
      @at-root .#{$competitivenessImage} & {
        min-height: 300px;
        background: url("../../assets/images/tms_competitiveness_#{$competitivenessImage}.png") no-repeat center center / contain;

        @media (min-width: 992px) {
          background-position: center left;
          background-size: 90%;
        }
      }
    }
  }

  &-inner {
    text-align: left;
    word-break: keep-all;

    @media (min-width: 992px) {
      width: 40%;
    }
  }

  &-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
    text-align: center;

    @media (min-width: 992px) {
      margin-bottom: 35px;
      text-align: left;
    }
  }

  &-desc {
    color: #222;
    letter-spacing: -0.3px;
    font-size: 18px;
    word-break: keep-all;
    text-align: center;

    @media (min-width: 992px) {
      font-size: 20px;
      text-align: left;
    }
  }
}

.hs-tms-channel {
  &-list {
    margin-top: 50px;
  }

  &-item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &:not(:last-child) {
      margin-bottom: 70px;
    }
  }

  &-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 80px;
    padding: 20px;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 26px;
    color: $point1;
    border-radius: 10px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
    background-color: #fff;
    margin: 0 auto 30px;
    word-break: keep-all;

    @media (min-width: 1200px) {
      margin: 0 50px 0 0;
      width: 160px;
      min-height: 160px;
    }
  }

  &-sub {
    &-list {
      text-align: left;
    }

    &-item {
      position: relative;
      font-size: 20px;
      color: #222;
      letter-spacing: -0.3px;
      padding-left: 18px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &::before {
        position: absolute;
        left: 0;
        top: 14px;
        display: block;
        width: 10px;
        height: 1px;
        background-color: #000;
        content: '';
      }
    }
  }

  &-image {
    display: none;
    width: 100%;
    margin-top: 40px;

    @media (min-width: 768px) {
      display: block;
    }

    $push: (push, 384);
    $sms: (sms, 320);

    @each $class, $height in $push, $sms {
      @at-root .#{$class} & {
        min-height: #{$height}px;
        background: url("../../assets/images/tms_channel_#{$class}.png") no-repeat center center / contain;
      }
    }
  }
}

.hs-tms-client-logo {
  width: 100%;

  &-wrap {
    margin-top: 50px;
  }
}