.hs-button {
    border-radius: 2px;
    padding: 10px;
    min-width: 200px;
    min-height: 40px;
    transition: all .2s;

    &.primary {
        background-color: #00653B;
        border: 1px solid #00653B;
        color: #fff;

        &:hover {
            background-color: #004025;
            border: 1px solid #004025;
        }
    }

    &.outline {
        border: 1px solid #00653B;

        &:hover {
            border: 1px solid #004025;
        }
    }

}