@import "../../common/variables";             // 변수
@import "../../common/mixin";                 // 믹스인

.hs-popup {
  width: 100%;
  height: 100%;
  max-width: 300px;
  max-height: 500px;
  background-color: #fff;
  @include position (fixed, 50%, null, null, 50%);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
    
  @media (min-width: 992px) {
    max-width: 460px;
    max-height: 660px;
  }

  &-container {
    @include position (fixed, 50%, null, null, 50%);
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .6);
    z-index: 200;
    display: none;

    &.active {
      display: block;
    }
  } // .hs-popup-container

  &-header {
    background-color: #00653B;
    text-align: center;
    padding: 15px 10px;
    box-sizing: border-box;

    &-title {
      font-size: 18px;
      color: #fff;
      font-weight: 500;
    } // .hs-popup-header-title
  } // .hs-popup-header

  &-body {
    padding-bottom: 52px;
    box-sizing: border-box;
    overflow-y: auto;
    position: relative;
    padding: 20px;
    flex: 1;

    &.image {
      padding: 0;
    }

    img {
      vertical-align: top;
    
      @media (max-width: 992px) {
        width: 100%;
      }
    }

    &-link {
      width: 240px;
      height: 165px;
      display: block;
      @include position(absolute, 50%, null, null, 50%);
      color: transparent;
      transform: translate(-50%, -35%);
    } // .hs-popup-body-link
  } // .hs-popup-body

  &-footer {
    text-align: center;
    padding: 15px;
    box-sizing: border-box;
    width: 100%;  
  } // .hs-popup-footer

  &-input {
    &-label {
      font-size: 14px;
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-right: 10px;
      }
    } // .hs-popup-input-label

    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
    } // .hs-popup-input-container

    &-check {
      margin-top: 6px;
    } // .hs-popup-input-check
  } // .hs-popup-input

  &-close-button {
    position: absolute;
    top: 20px;
    right: 18px;
    width: 20px;
    height: 20px;
    background: url("../../../assets/images/icon/hs_close_button.svg") center center / contain;
  } // .hs-popup-close-button
}