.hs-textarea {
    border: 1px solid #ccc;
    width: 100%;
    padding: 10px;
    border-radius: 2px;
    box-sizing: border-box;
    resize: none;

    &-label {
        display: block;
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: bold;
    } // .hs-textarea-label

    &-container {
        width: 100%;

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    } // .hs-textarea-container
}