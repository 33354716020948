::-moz-selection {
    color: #fff;
    background: $point1;
}
  
::selection {
    color: #fff;
    background: $point1;
}

.blind {
    @include blind;
}

.display-pc {
    display: none;
    
    @media (min-width: 992px) {
        display: block;
    }
}

.display-m {
    display: block;
    
    @media (min-width: 992px) {
        display: none;
    }
}