@import "../../common/variables";             // 변수
@import "../../common/mixin";                 // 믹스인

.hs-term-footer {
    text-align: center;
    padding: 20px 0;

    &-button {
        padding: 10px 25px;
        font-size: 10pt;
        background: #999999;
        color: #ffffff;
        cursor: pointer;
        border-radius: 2px;
        display: inline-block;
        font-weight: 500;

        &:hover {    
            background: #666666;
        }
    } // ..hs-term-footer-button
}