.hs-input {
    border: 1px solid #ccc;
    width: 100%;
    padding: 10px;
    min-height: 40px;
    border-radius: 2px;
    box-sizing: border-box;

    &-label {
        display: block;
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: bold;
    } // .hs-input-label

    &-container {
        width: 100%;

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    } // .hs-input-container
}