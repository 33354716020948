@import "./variables";

.hs-main {
    margin-top: 60px;

    @media (min-width: 992px) {
        margin-top: 72px;
    }
}

.hs-section {
    box-sizing: border-box;
    padding: 100px 0;
    width: 1440px;
    margin: 0 auto;
    @media (max-width: 430px) {
        width: 430px;
    }
    &.full-size {
        width: 100%;
        height: 880px;
        padding: 120px 0 0 0;
        margin: 0;
        .hs-section-inner {
            max-width: 100%;
            margin: 0;
            &-content {
                margin-top: 63px;
                &.tason-main {
                    width: 560px;
                    height: 348px;
                    background-repeat: no-repeat;
                    background-image: url('../../assets//images/tason_section.png');
                    @media (max-width: 430px) {
                        width: 430px;
                    }
                }
                &.ma-main {
                    width: 680px;
                    height: 582px;
                    background-repeat: no-repeat;
                    background-image: url('../../assets//images/ma_section.png');
                    margin-top: -60px;
                    @media (max-width: 430px) {
                        width: 430px;
                    }
                }
                &.tms-main {
                    width: 462px;
                    height: 383px;
                    background-repeat: no-repeat;
                    background-image: url('../../assets//images/tms_section.png');
                    @media (max-width: 430px) {
                        width: 430px;
                    }
                }
                &.tms-cloud-main {
                    width: 487px;
                    height: 387px;
                    background-repeat: no-repeat;
                    background-image: url('../../assets//images/tms_cloud_section.png');
                    @media (max-width: 430px) {
                        width: 430px;
                    }
                }
            }
            &.swiper-inner {
                width: 1440px;
                margin: 0 auto;
                @media (max-height: 750px) {
                    width: 1080px;
                }
                @media (max-width: 430px) {
                    width: 430px;
                }
            }
        }
    }
    &.slide {
        &.full-size {
            .hs-section-inner {
                max-width: 100%;
            }
        }
    }
    &.animate {
        &.to-bottom {
            transform: translateY(50px);
            transition: opacity 0.6s ease-out, transform 0.6s ease-out;
            opacity: 0;
            &-visible {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
    &.white1 {
        background-color: $white1;
        @media (max-width: 430px) {
            display: none;
        }
    }
    &.light-purple1 {
        background-color: $light-purple1;
    }
    &.light-purple2 {
        background-color: $light-purple2;
    }
    &.deep-purple1 {
        background-color: $deep-purple1;
    }
    &.deep-purple2 {
        background-color: $deep-purple2;
    }
    &.deep-purple3 {
        background-color: $deep-purple3;
    }

    picture img {
        width: 100%;
    }

    @media (max-width: 992px) {
        padding: 50px 20px;
    }

    @media (min-width: 992px) and (max-width: 1200px) {
        padding: 100px 50px;
    }

    &.gray {
        background-color: #F8F9FC;
        padding: 160px 110px 200px;
        @media (max-width: 430px) {
            padding: 20px;
        }
    }
    &.gradient {
        background: linear-gradient(180deg, #FFF 0%, #EAF2F8 100%);
        padding: 135px 175px;
        @media (max-width: 430px) {
            padding: 20px;
        }
    }
    &.dark-gradient {
        background: linear-gradient(134deg, rgba(208, 241, 5, 0.00) 39.61%, rgba(52, 186, 170, 0.20) 74.36%), url('../../assets/images/img_consult.png') lightgray 50% / cover no-repeat;
        background-blend-mode: normal, darken;
        padding: 135px 175px;
        @media (max-width: 430px) {
            padding: 20px;
        }
    }
    &.light-purple {
        background-color: $white1;
        padding: 140px 175px;
        @media (max-width: 430px) {
            padding: 20px;
        }
    }
    &.blue2 {
        background-color: $blue2;
        padding: 140px 175px;
        @media (max-width: 430px) {
            padding: 20px;
        }
    }
    &.slide {
        padding: 160px 0 200px;
        .hs-section-inner {
            max-width: 1440px;
            @media (max-width: 430px) {
                max-width: 430px;
            }
        }
        @media (max-width: 430px) {
            padding: 20px;
        }
    }
    &.introduce {
        // background: $green2;
        padding: 140px 520px;
        background-image: url('../../assets/images/main_bg_bottom.png');
        background-size: cover;
        width: 100%;
        height: 441px;
        @media (max-width: 430px) {
            padding: 20px;
            height: 250px;
        }
        .title {
            width: 500px;
            color: #FFF;
            font-family: Pretendard;
            font-size: 30px;
            font-weight: 700;
            color: #FFF;
            text-align: center;
            font-style: normal;
            line-height: 36px;
            margin: 0 auto 32px auto;
            @media (max-width: 430px) {
                width: 390px;
            }
        }
        button {
            width: 197px;
            height: 54px;
            flex-shrink: 0;
            border: 1px solid #fff;
            border-radius: 8px;
            transition: background-color 0.3s;
            background-color: transparent;
            a {
                color: $white;
                font-family: Pretendard;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: -0.18px;
            }
            &:hover {
                background-color: #09090A;
                border: none;
                a {
                    color: $white;
                }
            }
        }
    }

    &-inner {
        // max-width: 1090px;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        &.relative {
            position: relative;
        }

        .navigation-wrapper {
            width: 1300px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }

        .insight-swiper {
            .swiper-button-next,
            .swiper-button-prev {
                position: absolute;
                top: calc(50% + 55px);
                transform: translateY(-50%);
                z-index: 10;
                width: 60px; /* 네비게이션 버튼의 너비 */
                height: 60px; /* 네비게이션 버튼의 높이 */
                color: #fff; /* 네비게이션 버튼의 텍스트 색상 */
                border-radius: 50%; /* 네비게이션 버튼의 모양을 둥글게 만듭니다. */
                border: 1px solid $gray7;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;
                &::after {
                    color: #9B9B9B;
                    font-size: 16px;
                    font-weight: 700;
                }
            }
        }

        .swiper-button-next,
        .swiper-button-prev {
            position: absolute;
            top: calc(50% + 135px);
            transform: translateY(-50%);
            z-index: 10;
            width: 60px; /* 네비게이션 버튼의 너비 */
            height: 60px; /* 네비게이션 버튼의 높이 */
            color: #fff; /* 네비게이션 버튼의 텍스트 색상 */
            border-radius: 50%; /* 네비게이션 버튼의 모양을 둥글게 만듭니다. */
            border: 1px solid $gray7;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            &::after {
                color: #9B9B9B;
                font-size: 16px;
                font-weight: 700;
            }
        }
        .swiper-button-prev {
            left: var(--swiper-navigation-sides-offset, -100px);
        }

        .swiper-button-next {
            right: var(--swiper-navigation-sides-offset, -100px);
        }

        ~ .hs-section-inner {
            padding-top: 100px;
        }

        .text-left {
            text-align: left;
        }

        .text-center {
            text-align: center;
        }

        .text-bold {
            font-weight: bold;
        }

        .text-block {
            display: block;
        }
    } // .hs-section-inner

    &-title {
        font-size: 40px;
        margin-bottom: 30px;

        @media (min-width: 992px) {
            font-size: 60px;
            margin-bottom: 50px;
        }

        &-underline {
            display: inline-block;
            position: relative;
            z-index: 1;

            &::before {
                display: inline-block;
                width: 100%;
                height: 40px;
                position: absolute;
                left: 0;
                bottom: 10px;
                background-color: $point4;
                z-index: -1;
                content: '';
            }
        }

        .hs-title-text {
            font-weight: bold;
            font-size: 40px;

            @media (min-width: 992px) {
                font-size: 60px;
            }
        }

        .hs-sub-title-text {
            font-size: 26px;
            font-weight: normal;

            @media (min-width: 992px) {
                font-size: 40px;
            }
        }

        picture img {
            width: auto;
        }

        @media (max-width: 992px) {
            margin-bottom: 40px;

            picture img {
                width: 80%;
            }
        }
    } // .hs-section-title

    &-item {
        &:not(:last-of-type) {
            margin-bottom: 30px;
        }
    } // .hs-section-item
    &-inner {
        .swiper-wrapper {
        	transition-timing-function: linear;
        }
        &-content {
            width: 1200px;
            display: flex;
            justify-content: space-between;
            margin: 0 auto 94px;
            .left-content {
                width: 477px;
                color: $dark2;
                text-align: left;
                display: flex;
                flex-direction: column;
                align-items: self-start;
                justify-content: center;
                h4 {
                    font-size: 32px;
                    font-weight: 700;
                    line-height: 30px;
                    margin-bottom: 30px;
                }
                p {
                    letter-spacing: -1px;
                    font-size: 24px;
                    font-weight: 300;
                }
            }
            .right-content {
                display: inline-block;
                background-repeat: no-repeat;
                width: 556px;
                height: 335px;
                border-radius: 20px;
                &.tason {
                    background-image: url('../../assets/images/tason_content.png');
                }
                &.ma {
                    background-image: url('../../assets/images/ma_content.png');
                }
                &.tms {
                    background-image: url('../../assets/images/tms_content.png');
                }
                &.tms_cloud {
                    background-image: url('../../assets/images/tms_cloud_content.png');
                }
            }
        }
    }
    &-grid-layout {
        display: grid;
        gap: 2rem;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.hs-top-button {
    width: 80px;
    height: 80px;
    position: fixed;
    right: 20px;
    bottom: 20px;
    background: url("../../assets/images/button-top.svg") no-repeat center center / contain;
    opacity: 0;
    z-index: -1;
    transition: all .3s;

    &.show {
        opacity: 1;
        z-index: 10;
    }
}
.swiper-container {
    margin-bottom: 20px;
    &.relative {
        position: relative;
        &.far-top {
            top: 150px;
            @media (max-height: 750px) {
                top: 80px;
            }
            @media (max-width: 430px) {
                top: 30px;
            }
        }
    }
}
.hs-section.p-t-0 {
    padding-top: 0;
}
.swiper-slide img {
    height: 70px;
    background-size: contain;
    @media (max-width: 430px) {
        width: 80px;
        height: 40px;
    }
}
button.btn {
    width: 197px;
    height: 54px;
    border-radius: 8px;
    transition: background-color 0.3s;
    a {
        font-family: Pretendard;
        color: $white;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.18px;
    }
    &.btn-dark {
        color: $white;
        border: 1px solid $black2;
        background-color: $black2;
        &:hover {
            background-color: transparent;
            a {
                color: $black2;
            }
        }
    }
    &.btn-success {
        color: $white;
        border: 1px solid $green2;
        background-color: $green3;
        &:hover {
            background-color: transparent;
            a {
                color: $green3;
            }
        }
    }
    &.btn-primary {
        color: $white;
        width: 197px;
        height: 54px;
        border: 1px solid $blue4;
        background-color: $blue4;
        &:hover {
            background-color: transparent;
            a {
                color: $blue4;
            }
        }
    }
    &.btn-primary-light {
        color: $white;
        width: 197px;
        height: 54px;
        border: 1px solid $blue5;
        background-color: $blue5;
        &:hover {
            background-color: transparent;
            a {
                color: $blue5;
            }
        }
    }
    &.btn-primary2 {
        color: $white;
        width: 197px;
        height: 54px;
        border: 1px solid $deep-purple3;
        background-color: $deep-purple3;
        &:hover {
            background-color: transparent;
            a {
                color: $deep-purple3;
            }
        }
    }
}