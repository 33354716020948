@import "../../common/variables";             // 변수
@import "../../common/mixin";                 // 믹스인

.hs-header {
    min-height: 60px;
    width: 100%;
    box-sizing: border-box;
    // box-shadow: 0 10px 20px 0px rgb(0 0 0 / 10%);
    background-color: #fff;
    @include position (fixed, 0, null, null, 0);
    padding: 10px 20px;
    z-index: 100;

    @media (max-width: 992px) {
        display: flex;
        align-items: center;
    }

    @media (min-width: 992px) {
        min-height: 72px;
        padding: 0 50px;
    }

    @media (min-width: 1200px) {
        padding: 0;
    }
    @media (max-width: 430px) {
        width: 430px;
    }

    &-inner {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 0 auto;
        // max-width: 1440px;
        padding: 0 352px;
        box-sizing: border-box;
        @media (max-height: 750px) {
            padding: 0 132px;
        }
        @media (max-width: 430px) {
            padding: 0 20px;
        }
        nav {
            width: 100%;

            @media (max-width: 430px) {
                display: none;
            }
            // width: 844px;
        }
    } // .hs-header-inner
    
    &-logo {
        &-container {
        } // .hs-header-logo-container

        &-link {
            margin-right: 115px;
            display: inline-block;
        } // .hs-header-logo-link

        &-image {
            width: auto;
            height: 100%;
            max-height: 30px;
            vertical-align: top;

            @media (min-width: 992px) {
                max-height: 50px;
            }
        } // .hs-header-logo-image
    }

    &-menu {
        &-toggle-button {
            width: 25px;
            height: 25px;
            background: url("../../../assets/images/icon/hs_header_menu.svg") no-repeat center center / contain;

            @media (min-width: 992px) {
                display: none;
            }
        } // .hs-header-menu-toggle-button

        &-list {
            width: 100%;
            position: relative;
            @media (max-width: 992px) {
                display: none;
                position: absolute;
                top: 60px;
                right: 0;
                background-color: #fff;
                width: 100%;
                box-shadow: 0px 2px 15px 0px rgb(0 0 0 / 10%);

                &.active {
                    display: block;
                }
            }

            @media (min-width: 992px) {
                display: flex;
                align-items: center;
            }
        } // .hs-header-menu-list
        &-container {
            display: flex;
        }

        &-item {
            position: relative;
            min-height: 45px;

            &:not(:last-child) {
                border-bottom: 1px solid #eee;
            }

            @media (min-width: 992px) {
                &:not(:first-child) {
                    margin-left: 10px;
                }
            }
        }// .hs-header-menu-item

        &-link,
        &-button {
            display: flex;
            align-items: center;
            color: #222;
            font-weight: 500;
            padding: 10px;
            box-sizing: border-box;
            line-height: normal;
            font-size: 18px;
            min-height: 30px;
            color: #141414;
            letter-spacing: -0.16px;

            @media (max-width: 992px) {
                width: 100%;
                justify-content: center;
            }

            @media (min-width: 992px) {
                padding: 20px;
                min-height: 72px;
            }
        } // .hs-header-menu-link, .hs-header-menu-button

        &-button {
            &-item {
                @media (max-width: 430px) {
                    display: none;
                }
                @media (min-width: 992px) {
                    margin-left: 30px;
                    min-height: auto;
                    &:last-child {
                        // position: absolute;
                        // right: 0;
                    }
                }
            } // .hs-header-menu-button-item

            &-link {
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 500;
                box-sizing: border-box;
                color: #222;
                padding: 10px;
                line-height: normal;
                font-size: 16px;
                min-height: 45px;
                border-radius: 50px;
                width: 90px;
                height: 27px;
                &:last-child {
                    // width: 140px;
                }

                @media (min-width: 992px) {
                    font-size: 16px;
                    color: #fff;
                    transition: background-color .3s;
                    height: 40px;
                    // min-width: 140px;
                    min-height: auto;
                    border-radius: 50px;
                    background: #10A46E;
                }

                // &::after {
                //     display: block;
                //     width: 18px;
                //     height: 18px;
                //     margin-left: 5px;
                //     background: url("../../../assets/images/header_button_icon.svg") no-repeat center center / contain;
                //     content: '';
                // }
                @media (min-width: 992px) {
                    &:hover {
                        background-color: #105A3F;
                    }
                }

            } // .hs-header-menu-button-link
        }

        &-sub {
            &-list {
                display: none;
                width: auto;
                z-index: 100;
                border-top: 1px solid #eee;
                text-align: center;
                background-color: #f5f5f5;

                @media (min-width: 992px) {
                    text-align: left;
                    background-color: #fff;
                    @include position (absolute, 72px, null, null, 0);
                    min-width: 100px;
                    max-width: 1000px;
                    box-shadow: 0px 2px 15px 0px rgb(0 0 0 / 10%);
                }

                &.active {
                    display: block;
                }
            } // .hs-header-menu-sub-list

            &-item {
                &:not(:last-child) {
                    border-bottom: 1px solid #eee;
                }
            } // .hs-header-menu-sub-item

            &-link {
                display: block;
                padding: 15px;
                box-sizing: border-box;
                font-size: 13px;
                font-weight: 500;
                color: #222;
                line-height: normal;
                min-height: 45px;

                &:hover {
                    color: #4632da;
                }
            } // .hs-header-menu-sub-link
        }
    }
}